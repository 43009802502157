<template>
    <div class="py-1 w-40 rounded select-none" :style="{ color: text, backgroundColor: background }">
        <div class="text-2xl">
            {{ fixedAmount }}
        </div>
        <div>
            {{ label }}
        </div>
    </div>
</template>
<script>
import numbers from '@/utils/numbers';

export default {
    name: 'ChartDashboard',
    props: {
        label: {
            type: String,
            default: () => null,
        },
        amount: {
            type: Number,
            required: true,
        },
        background: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            default: () => '#FFFFFF',
        },
        customProps: {
            type: Object,
            default: () => null,
        },
    },
    computed: {
        fixedAmount() {
            return this.customProps?.isMoney ? this.moneyFormat(this.amount) : this.amount;
        },
    },
    methods: {
        moneyFormat(value) {
            const { currencySymbol } = this.$store.state.account;
            return numbers.moneyFormat(value, currencySymbol);
        },
    }
}
</script>